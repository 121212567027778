<template>
    <workspace-wrapper>
        <template #header>
            <default-slot-header :bet="stateData.currentSpinResult.bet" :lines="stateData.currentSpinResult.linesCount"
                :stake="stateData.currentSpinResult.stake" />

            <free-spins-slot-header
                v-if="stateData.currentSpinResult.bonusFreeSpins.booksBonusFreeSpins.totalFreeSpins != 0"
                v-bind="stateData.currentSpinResult.bonusFreeSpins.booksBonusFreeSpins" />
            <free-spins-slot-header
                v-if="stateData.currentSpinResult.bonusFreeSpins.bullsBonusFreeSpins.totalFreeSpins != 0"
                v-bind="stateData.currentSpinResult.bonusFreeSpins.bullsBonusFreeSpins" />
        </template>

        <template #workspace>
            <default-slot-workspace :layout="stateData.currentSpinResult.layout" :symbols-path="symbolsPath" />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../Common/WorkspaceWrapper";
import DefaultSlotHeader from "./Common/Header/DefaultSlotHeader";
import DefaultSlotWorkspace from "./Common/DefaultSlotWorkspace";
import FreeSpinsSlotHeader from "./Common/Header/FreeSpinsSlotHeader";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        balanceBefore: null,
                        bet: null,
                        linesCount: null,
                        stake: null,
                        layout: null,
                        isFreeSpin: null,
                        bonusFreeSpins: {
                            booksBonusFreeSpins: {
                                totalWin: null,
                                totalFreeSpins: null,
                                symbol: null,
                                currentFreeSpin: null,
                                freeSpinsAwardedCount: null
                            },
                            bullsBonusFreeSpins: {
                                totalWin: null,
                                totalFreeSpins: null,
                                frozenWilds: null,
                                currentFreeSpin: null,
                                freeSpinsAwardedCount: null
                            }

                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            symbolsPath: 'booksandbulls'
        }
    },
    components: {
        WorkspaceWrapper,
        DefaultSlotHeader,
        DefaultSlotWorkspace,
        FreeSpinsSlotHeader
    }
}
</script>

<style scoped></style>
